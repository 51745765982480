import { SubscriptionData, UserData } from '@prisma/client'
import axios from 'axios'
import { Profile } from '../interfaces/profile'

export const getUser = async (): Promise<Profile | null> => {
  try {
    const {
      data: {
        user: { userData, subscriptionData, _count },
      },
    } = await axios.get(`/api/user`)

    const profileData: Profile = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      isVerified: userData.isVerified,
      isAdmin: userData.isAdmin,
      isFirstTimer: userData.isFirstTimer,
      accountProvider: userData.accountProvider,
      notifications: userData.notifications,
      subscriptionData: subscriptionData,
      numberOfSearches: _count.search,
    }

    return profileData
  } catch (err) {
    console.error(err)
    return null
  }
}

export const updateUserData = async (
  updateQuery: Partial<UserData>
): Promise<Profile | null> => {
  const { data: updatedUserData } = await axios.put(`/api/user`, {
    updateQuery,
  })
  return updatedUserData
}

export const updateSubscriptionData = async (
  updateQuery: Partial<SubscriptionData>
): Promise<SubscriptionData> => {
  const { data } = await axios.put(`/api/user/subscription`, {
    updateQuery,
  })

  return data
}

export const getSubscriptionData = async (): Promise<SubscriptionData> => {
  const { data } = await axios.get(`/api/user/subscription`)

  return data
}

export const isExistingEmail = async (email: string) => {
  const { data } = await axios.post(`api/user/isExistingEmail`, { email })
  return Boolean(data.isExistingEmail)
}

export const changePassword = async (
  oldPassword: string,
  newPassword: string
): Promise<boolean> => {
  const {
    data: { success },
  } = await axios.post(`/api/user/changePassword`, {
    oldPassword,
    newPassword,
  })
  return success
}

export const updateUserEmail = async (newEmail: string): Promise<boolean> => {
  const {
    data: { success },
  } = await axios.put(`api/user/email`, { newEmail })
  return Boolean(success)
}

export const verifyUserEmail = async (verificationToken: string | string[]) => {
  await axios.post(`/api/auth/verifyEmail`, { verificationToken })
}

export const resendVerificationEmail = async () => {
  await axios.post(`/api/auth/resendVerificationEmail`)
}

export const createUser = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  profession: string,
  heardFrom: string,
  initPlan: string | null
): Promise<any> => {
  const data = await axios.post(`/api/auth/credentialsSignup`, {
    email,
    password,
    firstName,
    lastName,
    profession,
    heardFrom,
    initPlan,
  })
  return data
}

export const updateStartedCheckout = async (
  finished: boolean = false
): Promise<void> => {
  try {
    await axios.put(`/api/user/startedCheckout`, { hasFinished: finished })
  } catch (err) {
    console.error('Failed to update started checkout', err)
  }
}
